import { useEffect,useState } from 'react';
import styles from './App.module.css';
import parse_xml from '../../util/parse-xml';

function App() {
  const [data,setData] = useState([])
  const url = process.env.REACT_APP_URL

  useEffect(()=>{
    (async () => {
      const items = await parse_xml(url);
      setData(items);
    })();
  },[])

  return (
    <div className={styles.appWrapper}>
      <table className={styles.styledTable}>
        <thead>
          <tr>
              <th>Thumbnail</th>
              <th>Title</th>
              <th>Category</th>
              <th className={styles.price}>Price</th>
              <th>Stock</th>
              <th>Rating/<br/>Review</th>
              <th>SKU</th>
              <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item)=>(
            <tr key={item["g:id"]}>
              <td>
                <img src={item["g:image_link"]}/>
              </td>
              <td>{item["g:title"]}</td>
              <td>{item["g:google_product_category"]}</td>
              <td>{
              item["g:sale_price"]?
                <><span className={styles.striked}>{item["g:price"]}</span><br/>{item["g:sale_price"]}</>:
                item["g:price"]
            }</td>
              <td>{item["g:availability"] === "in_stock"? "Y":"N"}</td>
              <td>{item["g:product_rating"] && item["g:number_of_reviews"] ? `${item["g:product_rating"]} / ${item["g:number_of_reviews"]}` :"None"}</td>
              <td>{item["g:mpn"]}</td>
              <td>
                <a href={item["g:link"]} target='_blank'>View</a>
                <button onClick={()=>{
                  navigator.clipboard.writeText(item["g:link"])
                }}>Copy Link</button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
