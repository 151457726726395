import axios from "axios"
import { XMLParser} from "fast-xml-parser";

const parse_xml = async(url)=>{
    const response = await(axios.get(url,{
        headers:{
            "Content-Type":"application/xml"
        }
    }))
    const data = response.data
    return convert_xml(data)
}

const convert_xml = (xml)=>{
    const parser = new XMLParser();
    let result = parser.parse(xml);
    if(result && result["rss"] && result["rss"]["channel"] && result["rss"]["channel"]["item"]){
        return result["rss"]["channel"]["item"]
    }else{
        throw new Error("XML Fetch Failed")
    }
}

export default parse_xml